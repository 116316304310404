<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
    <p>{{ message }}</p>
</div>
<div class="modal-footer">
    <button type="button" (click)="sendConfirm()" class="btn btn-primary">Accept</button>
    <button type="button" (click)="sendCancel()" class="btn btn-light">Cancel</button>
</div>