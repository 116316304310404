import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';

import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { DatatableComponent } from './datatable/datatable.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ShowErrorsComponent } from './forms/show-errors/show-errors.component';


@NgModule({
  declarations: [
    GoogleMapsComponent,
    DatatableComponent,
    BreadcrumbsComponent,
    ConfirmationDialogComponent,
    ShowErrorsComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DataTablesModule,
    ModalModule.forRoot()
  ],
  exports: [
    GoogleMapsComponent,
    DatatableComponent,
    ConfirmationDialogComponent,
    ShowErrorsComponent
  ]
})
export class WidgetsModule { }
