import { Login } from "./login";

export class LoginForm implements Login {
    public email: string;
    public password: string;
    
    public constructor (
        public data: any = {}
    ) {
        this.email = data.email || '';
        this.password = data.password || '';
    }
}
