<div class="card">
    <div class="card-header">
        <div class="row justify-content-between">
            <div class="col-6">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item">
                            <a *ngIf="breadcrumb.link" [routerLink]="[breadcrumb.link]">{{ breadcrumb.title }}</a>
                            <span *ngIf="!breadcrumb.link">{{ breadcrumb.title }}</span>
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="col-6 text-end">
                <a *ngIf="upperAction" (click)="upperActionButton()" class="btn btn-link">{{ upperAction }}</a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <app-datatable *ngIf="settings" [reload]="reload" [settings]="settings" (datatableAction)="action($event)" (datatableComponent)="modifyInstanceList($event)"></app-datatable>
    </div>
</div>

<ng-template #templateDelete>
    <app-confirmation-dialog [settings]="alertSettings" (confirm)="delete($event)" (close)="closeModal()"></app-confirmation-dialog>
</ng-template>