import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SocketService } from '../services/socket.service';

/**
 * Guard service used for lazy-loading that determines whether to load or not a module based on whether the user logged in or not.
 * Thus, if the user is not logged, Angular will not load the module.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  /**
   * Builds the guard.
   * @param {AuthService} auth Manages users' authentication.
   * @param {Router} router Navigation and url manipulation.
   * @param {SocketService} socket SocketIO client service.
   */
  public constructor(
    private auth: AuthService,
    private router: Router,
    private socket: SocketService
  ) { }

  /**
   * Determines whether to load a module based on whether the user is logged in.
   * If the user is not logged in, the module is not loaded and the user is taken to the login page.
   * If the user is logged in, the module is loaded and the SocketIO Client module is initiated.
   * @returns {boolean}
   */
  public canLoad(): boolean {
    if (!this.auth.logged()) {
      this.router.navigate(['/login']);
      return false;
    }
    this.socket.init();
    return true;
  }
}
