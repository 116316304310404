import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent {

  private static readonly errorMessages: any = {
    required: () => '##FIELD## can\'t be blank',
    minlength: (params: any) => '##FIELD## should be minimum ' + params.requiredLength + ' characters',
    min: (params: any) => '##FIELD## field should be minimum ' + params.min + ' value',
    max: (params: any) => '##FIELD## field should be maximum ' + params.max + ' value',
    maxlength: (params: any) => '##FIELD## should not be greater then ' + params.requiredLength + ' characters',
    pattern: () => 'Should be a valid ##FIELD##',
    email: () => 'Should be valid email.',
    mustMatch: () => '##FIELD## does not match'
  };


  @Input()
  public control!: AbstractControlDirective | AbstractControl;

  shouldShowErrors(): boolean | null {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors!)
      .map(field => this.getMessage(field, this.control.errors![field], this.control));
  }

  getError(): string {
    var errors = Object.keys(this.control.errors!)
      .map(field => this.getMessage(field, this.control.errors![field],this.control));
    return errors[0];
  }

  private getMessage(type: string, params: any, control:any) {
    let fname = this.getControlName(control);
    if (fname) {
      fname = fname.replace("_"," ").replace(" id","").toLowerCase();
      fname = fname.replace(/\b\w/g, l => l.toUpperCase());
      var msg = ShowErrorsComponent.errorMessages[type](params);
      return msg.replace("##FIELD##",fname);
    }
  }

  getControlName(c: AbstractControl): string | null | undefined {
    if (c.parent) {
      const formGroup: any = c.parent.controls;
      if (formGroup) {
        return Object.keys(formGroup).find(name => c === formGroup[name]);
      }
    }
    return null;
  }
}
