import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from './shared/services/routing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'gozmart-iothub-frontend';

  constructor(
    private router: Router,
    private routing: RoutingService
  ) {
    this.routing.loadRouting()
  }

  ngOnInit(): void {
    if (window.location.pathname === '/') {
      this.router.navigate(['dashboard']);
    }
  }
}
