export const environment = {
    production: false,
    version: '1.0RC1 - 220515',
    apiDir: '/api/v1',
    backend: 'https://gzih-dev.gozmart.ch',
    company: 'goZmart Iberia',
    product: 'goZmart IoT Hub Panel',
    productApi: 'goZmart IoT Hub API',
    productAcronym: 'gZIH',
    startYear: '2017'
};