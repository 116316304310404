import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { TemplatesModule } from '../templates/templates.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    TemplatesModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    LayoutModule
  ]
})
export class AuthenticationModule { }
