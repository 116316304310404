import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { StorageKeys } from '../constants';

/**
 * Service to provide a backend connection through web sockets.
 */
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  /**
   * Web socket instance.
   */
  private socket: any;
  /**
   * Socket server address.
   */
  private socketUrl: string = `${environment.backend}`;
  /**
   * Value of the user's token stored locally.
   */
  private tokenValue: string | null | undefined;

  /**
   * Build the service.
   */
  public constructor() { }

  /**
   * Initialize the web socket
   */
  public init(): void {
    this.tokenValue = localStorage.getItem(StorageKeys.TOKENS.VALUE);
    if (!this.socket) {
      const token = this.tokenValue;
      this.socket = io(this.socketUrl, { query: { token }, transports: ['websocket'] });
      this.socket.on('connect', () => {
        console.log('SOCKET INIT IN:::>', this.socketUrl, 'WITH TOKEN::> ', token);
      })
    } else if (this.socket.disconnected) {
      this.socket.connect();
    } else {
      console.log('SOCKET IS RUNNING IN::>', this.socketUrl);
    }
  }

  public disconnect(): void {
    if (this.socket.connected) {
      this.socket.disconnect();
    }
  }

  /**
   * Returns an observable which emits events on measure:created socket event.
   * @returns {Observable}
   */
  public onMeasureCreated(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('measure:created', (data: any) => observer.next(data));
    });
  }

  /**
   * Returns an observable which emits events on measure:created socket event.
   * @returns {Observable}
   */
  public onUserAvailabilityChanged(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('user:availability', (data: any) => observer.next(data));
    });
  }
}
