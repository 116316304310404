import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  /**
   * Several options datatables manage.
   */
  public dtOptions: any;
  /**
   * We use this trigger because fetching a list can be quite long, thus we ensure the data is fetched before rendering.
   * Internal attribute for datatables.
   */
  public dtTrigger: Subject<any> = new Subject<any>();
  private request$?: Subscription;

  /**
   * Edit button in html.
   */
  private editActionHtml: any = `
    <button type='button' class='btn btn-edit btn-light' data-bs-toggle='tooltip' data-bs-placement='top' title='Edit'>
      <i class='bi bi-pencil-square'></i>
    </button>`;
  /**
   * Delete button in html.
   */
  private deleteActionHtml: any = `
    <button type='button' class='btn btn-delete btn-danger' data-bs-toggle='tooltip' data-bs-placement='top' title='Delete'>
      <i class='bi bi-trash'></i>
    </button>`;

  /**
   * Options for datatables coming from a wrapping component.
   */
  @Input() settings: any
  /**
   * One of the variables that determines whether the table should be refreshed or not, on datatable changes.
   */
  @Input() reload: any;
  /**
   * Emits info about what action the user chose to be performed for a row in the datatable.
   */
  @Output() datatableAction: EventEmitter<any> = new EventEmitter;
  /**
   * Emits info about datatable instance.
   */
  @Output() datatableComponent: EventEmitter<any> = new EventEmitter();
  /**
   * Element datatable from the component HTML.
   */
  @ViewChild(DataTableDirective, { static: true }) element?: DataTableDirective;

  /**
   * Build the component and initialize certain attributes.
   * @param {HttpClient} http Service to make API calls.
   */
  constructor(private http: HttpClient) { }

  /**
   * Initializes certain variables and most important, the datatables attributes.
   */
  ngOnInit(): void {
    const columns = this.getColumns();
    this.dtOptions = {
      // Defines the pagination control below the table.
      pagingType: 'full_numbers',
      // Number of rows to display on a single page when using pagination.
      pageLength: 10,
      // Determines whether filtering, paging and sorting calculations are all performed by a server.
      serverSide: true,
      // Enable or disable the display of a 'processing' indicator when the table is being processed.
      processing: true,
      // Enable or disable state saving. When enabled aDataTables will store state information
      // such as pagination position, display length, filtering and sorting. When the end user
      // reloads the page the table's state will be altered to match what they had previously set up.
      stateSave: true,
      // Load data for the table's content from an Ajax source, in this case, a function.
      // This method is executed only at the display and for interactions with the datatable. Not automatically every certain time.
      // dataTablesParameters contains current values of the datatable.
      ajax: (dataTablesParameters: any, callback: any) => {
        let order = '';
        if (dataTablesParameters.order.length) {
          order +=
            dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
          if (dataTablesParameters.order[0].dir === 'asc') {
            order = '+' + order;
          } else {
            order = '-' + order;
          }
        }

        let params: any = {
          search: dataTablesParameters.search.value,
          include: this.settings.include || [],
          limit: dataTablesParameters.length,
          page: +(dataTablesParameters.start / dataTablesParameters.length) + 1,
          sort: order
        };

        params = {
          ...this.removeEmptyFields(params),
          ...this.settings.filter
        };

        this.request$ = this.http
          .get(`${environment.backend + environment.apiDir}/${this.settings.section}`, {
            params: params
          })
          .subscribe((res: any) => {
            if (!res.docs || res.docs.length === 0) {
              if (res.page !== 1) {
                this.redrawTable();
              } else {
                callback({
                  recordsTotal: res.totalDocs,
                  recordsFiltered: res.totalDocs,
                  data: []
                });
              }
            } else {
              if (this.settings.renderStatusColumns) {
                callback({
                  recordsTotal: res.totalDocs,
                  recordsFiltered: res.totalDocs,
                  data: this.settings.renderStatusColumns(res.docs)
                });
              } else {
                callback({
                  recordsTotal: res.totalDocs,
                  recordsFiltered: res.totalDocs,
                  data: res.docs
                });
              }
              if (this.settings.instance) {
                this.element?.dtInstance.then((dtInstance: DataTables.Api) => {
                  this.datatableComponent.emit(dtInstance);
                });
              }
            }
          });
        return this.request$;
      },
      // Allows you to define details about the way individual columns behave.
      columns: columns,
      rowCallback: (row: any, data: any[] | Object, index: number) => {
        const self = this;

        if (this.settings.rowCallback) {
          row = this.settings.rowCallback(row, data, index);
        }

        $('td .btn-edit', row).off('click');
        $('td .btn-delete', row).off('click');

        $('td .btn-edit', row).on('click', () => {
          self.editClickHandler(data);
        });
        $('td .btn-delete', row).on('click', () => {
          self.deleteClickHandler(data);
        });
      }
    }
  }

  /**
   * Lifecycle hook that is called after a component's view has been fully initialized.
   */
  ngAfterViewInit(): void {
    // Renders the table.
    this.dtTrigger.next(this.dtOptions);
  }

  /**
   * On any of the component @input references changes.
   * @param {SimpleChanges} changes Used to get new and previous values of input properties.
   */
   ngOnChanges(changes: SimpleChanges) {
    // If there are changes in reload.
    if (changes['reload']) {
      if (changes['reload'].currentValue) {
        this.reloadTable();
      }
    }
  }

  /**
   * Reloads the datatable with the latest data.
   */
  private async reloadTable() {
    const dtInstance = await this.element?.dtInstance;
    // Ajax request to the already defined URL.
    dtInstance?.ajax.reload(() => {}, false);
  }

  /**
   * Returns an array with the datatables columns and some of their options.
   */
  getColumns() {
    let defaultContent: any = '';
    const columns = [...this.settings.columns];

    if (!this.settings.no_id) {
      columns.push({
        title: '#ID',
        data: '_id',
        visible: false,
        searchable: false
      });
    }

    if (this.settings.action) {
      let actionColumWidth = 90;
      if (this.settings.action.length) {
        actionColumWidth = this.settings.action.length * 30;
        for (let i = 0; i < this.settings.action.length; i++) {
          const action = this.settings.action[i];
          switch (action) {
            case 'edit':
              defaultContent += this.editActionHtml;
              break;
            case 'delete':
              defaultContent += this.deleteActionHtml;
              break;
            default:
              if (
                this.settings.actionButtons &&
                this.settings.actionButtons[action]
              ) {
                defaultContent += this.settings.actionButtons[action];
              }
          }
        }
      }

      columns.push({
        title: 'Actions',
        width: actionColumWidth + 'px',
        targets: -1,
        searchable: false,
        data: null,
        defaultContent: `<td><div class='btn-group'>${defaultContent}</div></td>`,
        orderable: false
      });
    }

    return columns;
  }

  /**
   * Generic function to remove empty attributes from an object.
   * @param obj Object from which to remove empty fields.
   */
  private removeEmptyFields(obj: any) {
    const newObj: any = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }

  private async redrawTable() {
    const dtInstance = await this.element?.dtInstance;
    dtInstance?.draw();
  }

  /**
   * Handles the click event for the remove button.
   * @param info Information needed for the remove action.
   */
  private deleteClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'delete',
      data: info
    });
  }

  /**
   * Handles the click event for the edit button.
   * @param info Information needed for the edit action.
   */
  private editClickHandler(info: any): void {
    this.datatableAction.emit({
      action: 'edit',
      data: info
    });
  }

  /**
   * Lifecycle hook that is called when a directive, pipe or service is destroyed.
   */
  ngOnDestroy() {
    if (this.request$) {
      this.request$.unsubscribe();
    }
  }
}
