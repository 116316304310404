import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/login-form';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private apiAddress = environment.backend + environment.apiDir + '/admins';

  constructor(
    private http: HttpClient
  ) { }

  public login(data: LoginForm) {
    return this.http.post(`${this.apiAddress}/login`, data);
  }
  public showAll(params: any = {}) {
    return this.http.get(`${this.apiAddress}`, { params: params });
  }
  public update(id: string, data: any) {
    return this.http.put(`${this.apiAddress}/${id}`, data);
  }
  public show(id: string) {
    return this.http.get(`${this.apiAddress}/${id}`);
  }
  public changePassword(data: any) {
    return this.http.post(`${this.apiAddress}/change-password`, data);
  }
  public create(data: any) {
    return this.http.post(`${this.apiAddress}`, data);
  }
  public delete(id: string) {
    return this.http.delete(`${this.apiAddress}/${id}`);
  }
}
