<footer class="footer">
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-12 text-center col-md-6 text-md-start">
                <span>{{ product }} - <b>{{ version }}</b></span>
            </div>
            <div class="col-12 text-center col-md-6 text-md-end">
                <span>Copyright &copy; {{ startYear }}-{{ date | date: 'yy' }} {{ company }}. <b>All rights reserved.</b></span>
            </div>
        </div>
    </div>
</footer>