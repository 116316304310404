import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  
  private history: any[] = [];

  constructor(
    private router: Router
  ) { }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(({ url }: any) => {
        this.history = [...this.history, url];
      });
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/dashboard';
  }
}
