import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap, throwError } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  private whiteList = [
    'mobile/',
    '.css',
    '.js',
    'googleapis.com',
    'assets/i18n',
    'gstatic.com',
    'assets/custom',
    'socket.io',
    '.json',
    'admins/login'
  ];

  constructor(
    private auth: AuthService,
    private location: Location
  ) { }

  private addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    if (token) {
      let headers = req.headers.set('Authorization', 'Bearer ' + token);

      return req.clone({ headers });
    } else {
      return req;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();

    let onWhiteList = false;
    this.whiteList.forEach(function (rule) {
      if (req.url.indexOf(rule) > -1) {
        onWhiteList = true;
      }
    });
    if (!onWhiteList) {
      req = this.addToken(req, this.auth.getAuthToken());
    }

    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
        }
      },
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          const elapsed = Date.now() - started;
          console.log(
            `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
          );

          switch ((<HttpErrorResponse>error).status) {
            case 400:
              console.log('400', error);
              return this.handle400Error(error);
            case 401:
              console.log('401', error);
              return this.handle401Error(req, next);
          }
        } else {
          return throwError(() => new Error(error));
        }
      }
    ));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    this.location.back();
  }
  private handle400Error(error: any) {
    return throwError(() => new Error(error));
  }
}
