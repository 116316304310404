import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { LoginForm } from '../models/login-form';
import { StorageKeys } from '../constants';
import { Router } from '@angular/router';
import { SocketService } from './socket.service';
import { AdminService } from './admin.service';
import * as moment from 'moment';

/**
 * Manages all functions related to users' authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentToken: string | null = null;
  private currentUser: any;
  private tokenKey = 'localhost:token';
  private expired: any;

  /**
   * Build the service
   * @param {AdminService} admin Backend Admins API.
   * @param {Router} router Navigation and url manipulation.
   */
  public constructor(
    private admin: AdminService,
    private router: Router,
    private socket: SocketService
  ) { }

  /**
   * Performs a login by creating a session in the backend.
   * @param {LoginForm} data Contains the data from the login form.
   * @returns {Observable<any>} An observable that will contain the backend's response.
   */
  public login(data: LoginForm): Observable<any>  {
    return this.admin.login(data)
      .pipe(map(res => {
        return this.setSession(res);
      }));
  }

  /**
   * Logs out the user while cleaning local storage and disconnecting the websocket.
   */
  public logout(): void {
    localStorage.removeItem(StorageKeys.TOKENS.VALUE);
    this.router.navigate(['/login']);
    this.socket.disconnect();
  }

  /**
   * Determines whether a user is logged.
   * (Since currently the backend jwt generation does not add expiration time, the only way to determine whether a user is logged is by checking for a token stored locally)
   * @returns {boolean}
   */
  public logged(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  public getAuthToken() {
    if (!this.currentToken) {
      this.currentToken = localStorage.getItem(this.tokenKey);
    }
    if (!this.currentToken) {
      return;
    }
    return this.currentToken;
  }

  public getAuthUser() {
    try {
      if (!this.currentUser) {
        const localStorageUser = localStorage.getItem(this.tokenKey + ':user');
        if (localStorageUser) {
          this.currentUser = JSON.parse(localStorageUser);
        }
      }
      if (!this.currentUser) {
        this.logout();
        return;
      }
      return this.currentUser;
    } catch (e) {
      this.logout();
      return;
    }
  }

  private setSession(res: any) {
    // res.expires_at
    const expiresAt = moment().add(1, 'day');
    if (res.user) {
      const user = res.user;
      this.setSessionUser(user);
      localStorage.setItem(this.tokenKey, res.token);
      localStorage.setItem(
        this.tokenKey + ':expires_at',
        JSON.stringify(expiresAt.valueOf())
      );
      this.currentUser = res.user;
      this.expired = expiresAt;
      this.currentToken = res.token;
      return res;
    }
    return res;
  }

  private setSessionUser(user: any) {
    if (!user) {
      return;
    }

    if (user.organization) {
      const organization = user.organization;

      user.organization = organization._id;
      user.organizationObj = organization;
    }

    delete user.password;
    delete user.created_at;
    delete user.active;
    localStorage.setItem(this.tokenKey + ':user', JSON.stringify(user));
  }

  private getExpiration() {
    if (!this.expired) {
      const expiration = localStorage.getItem(this.tokenKey + ':expires_at');
      if (expiration) {
        this.expired = JSON.parse(expiration);
      }
    }
    if (!this.expired && this.currentUser) {
      this.logout();
      return;
    }
    return moment(this.expired);
  }
}
