export class StorageKeys {
    static TOKENS = {
        VALUE: 'gzih:token',
        USER: 'gzih:token:user'
    }
}
export class AssetsPaths {
    static IMAGES = {
        LOGIN_BG: '/assets/images/login-bg.png',
        DEFAULT_LOGIN_BG: '/assets/images/default_login-bg.png'
    }
}
export class UserManagement {
    static ROLES = {
        USER: 'user',
        MANAGER: 'manager',
        ADMIN: 'admin',
        SUPERADMIN: 'superadmin'
    }
}