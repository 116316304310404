import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { ListComponent } from './list/list.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { FormComponent } from './form/form.component';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    ToolbarComponent,
    FooterComponent,
    ListComponent,
    FormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    WidgetsModule
  ],
  exports: [
    BasicLayoutComponent,
    ToolbarComponent,
    FooterComponent,
    ListComponent
  ]
})
export class TemplatesModule { }
