import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapMarker } from '@angular/google-maps';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent {

  @Input() public markers!: MapMarker[];
  @Output() private positionChanged: EventEmitter<any> = new EventEmitter();
  public apiLoaded: Observable<boolean>;
  public markerClustererImagePath: string = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

  constructor(http: HttpClient) {
    this.apiLoaded = http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAo4LEWxTzZtryEiGaDOLFPwRErcdLmaqM', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public markerChanged(event: any) {
    const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng()}
    this.positionChanged.emit(latLng)
  }
}
