import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetsPaths } from 'src/app/shared/constants';
import { LoginForm } from 'src/app/shared/models/login-form';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/shared/services/routing.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public backgroundImageUrl: string = AssetsPaths.IMAGES.LOGIN_BG;
  private loginForm: LoginForm = new LoginForm();
  public form: FormGroup = this.formBuilder.group({
    email: new FormControl(this.loginForm.email, [Validators.required, Validators.email]),
    password: new FormControl(this.loginForm.password, [Validators.required])
  });
  public error: any = {
    success: null,
    message: null
  };

  private login$: Subscription = new Subscription();

  public constructor(
    private router: Router,
    private routing: RoutingService,
    private formBuilder: FormBuilder,
    private auth: AuthService
  ) { }

  public ngOnInit(): void {
    this.setBackgroundImage();
  }

  private setBackgroundImage() {
    const img = new Image();
    img.src = this.backgroundImageUrl;
    if (!img.complete) {
      img.onerror = () => {
        this.backgroundImageUrl = AssetsPaths.IMAGES.DEFAULT_LOGIN_BG;
      }
    }
  }

  /**
   * onSubmit
   */
  public onSubmit() {
    if (this.form.valid) {
      this.login$.add(
        this.auth.login(this.form.value).subscribe({
          next: () => {
            this.router.navigate([this.routing.getPreviousUrl()]);
          },
          error: (err) => {
            this.error.success = false;
            this.error.message = 'Username and password do not match';
            console.warn(this.error);
          }
        })
      )
    }
  }

  public ngOnDestroy(): void {
    if (this.login$) {
      this.login$.unsubscribe();
    }
  }
}
