import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  private action$!: Subscription;

  @Input() public reload?: any = '';
  @Input() public settings: any;
  @Input() public service: any;
  @Input() public upperAction?: string;
  @Input() public breadcrumbs: any;
  @Output() private specificAction: EventEmitter<any> = new EventEmitter();
  @Output() private specificUpperAction: EventEmitter<any> = new EventEmitter();
  @Output() public modifyInstance: EventEmitter<any> = new EventEmitter();

  /**
   * Bootstrap modal used to display unlink and remove confirmation dialogs.
   */
  public bsModalRef: BsModalRef | undefined;
  /**
   * Attributes related to the bootstrap modal.
   * @see bsModalRef.
   */
  public alertSettings: any = {};

  /**
   * Template to create the remove confirmation dialog.
   */
  @ViewChild('templateDelete', { static: true }) private templateDelete!: TemplateRef<any>;

  constructor(
    private modals: BsModalService,
    private router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    if (this.action$) {
      this.action$.unsubscribe();
    }
  }

  public action(event: any) {
    switch (event.action) {
      case 'edit':
        this.router.navigate([this.settings.route, event.data._id, 'edit']);
        break;

      case 'delete':
        this.openDeleteModal(this.templateDelete, event.data);
        break;

      default:
        this.specificAction.emit(event);
        break;
    }
  }

  private openDeleteModal(template: TemplateRef<any>, item: any) {
    this.alertSettings = {
      title: 'Delete',
      message: 'Are you sure?',
      item: item
    };
    this.bsModalRef = this.modals.show(template, { class: 'modal-md' });
  }

  public delete(item: any) {
    this.action$ = this.service.delete(item._id).subscribe({
      next: () => {
        this.reload = Date();
      },
      error: console.error,
      complete: () => {
        this.bsModalRef?.hide();
      }
    })
  }

  public closeModal() {
    this.bsModalRef?.hide();
  }

  public upperActionButton() {
    if (this.upperAction && this.upperAction !== 'Add new') {
      this.specificUpperAction.emit();
    } else {
      this.router.navigate(['create'], { relativeTo: this.route });
    }
  }

  public modifyInstanceList(event: any) {
    this.modifyInstance.emit(event);
  }
}
