import { Component, OnInit } from '@angular/core';
import { Admin } from 'src/app/shared/models/admin';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public apiDocsAddress: string = environment.backend + '/api-docs';
  public user: Admin;

  constructor(private auth: AuthService) {
    this.user = this.auth.getAuthUser();
  }

  ngOnInit(): void {
  }

  /**
   * logout
   */
  public logout() {
    this.auth.logout()
  }
}
