<nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['']" tabindex="1">
            <img src="assets/images/logo.png" onerror="this.src='assets/images/default_logo.png'" alt="Company's logo" width="100px">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
                </li>
                <li *ngIf="user.role === 'superadmin'" class="nav-item">
                    <a class="nav-link" [routerLink]="['/admins']">Admins</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/apps']">Apps</a>
                </li>
                <li *ngIf="user.role === 'superadmin'" class="nav-item">
                    <a class="nav-link" [routerLink]="['/gateways']">Gateways</a>
                </li>
                <li *ngIf="user.role === 'superadmin'" class="nav-item">
                    <a class="nav-link" [routerLink]="['/devices']">Nodes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/schemes']">Schemes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/sources']">Sources</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [href]="apiDocsAddress" target="_blank">API</a>
                </li>
                <li class="nav-item">
                    <button class="btn btn-outline-success" (click)="logout()"><i class="bi bi-box-arrow-right"></i></button>
                </li>
            </ul>
        </div>
    </div>
</nav>
