import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() public settings: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  public title!: string;
  public message: any;
  public item: any;

  constructor() { }

  ngOnInit(): void {
    this.title = this.settings.title;
    this.message = this.settings.message;
    this.item = this.settings.item;
  }

  sendCancel(){
    this.close.emit(true);
  }
  sendConfirm(){
    this.confirm.emit(this.item);
  }

}
