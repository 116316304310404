<div class="container-fluid vh-100">
    <div class="row vh-100">
        <div class="col-12 col-md-4">
            <div class="col-12 text-center">
                <img width="70%" src="assets/images/default_login-logo.png">
            </div>
            <div class="col-12">
                <form class="p-5" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div *ngIf="error.success !== null && error.success === false" class="alert alert-danger">
                        {{ error.message }}
                    </div>

                    <div class="mb-3" [ngClass]="{
                        'has-danger': form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched),
                        'has-success': form.controls['email'].valid && (form.controls['email'].dirty || form.controls['email'].touched)
                      }">
                        <label for="email">E-mail</label>
                        <input type="email" class="form-control" id="email" formControlName="email">
                    </div>
                    <div class="mb-3" [ngClass]="{
                        'has-danger': form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched),
                        'has-success': form.controls['password'].valid && (form.controls['password'].dirty || form.controls['password'].touched)
                      }">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" id="password" formControlName="password">
                    </div>
                    <div class="mb-3">
                        <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Sign In</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="login-background" class="d-none d-md-block col-md-8"
            [ngStyle]="{ 'background-image': 'url(' + backgroundImageUrl + ')' }">
            <div>
                <h1>IoT Hub</h1>
                <p>IoT Hub designed to achieve high availability</p>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
