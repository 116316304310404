import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public product: string = environment.product;
  public version: string = environment.version;
  public startYear: string = environment.startYear;
  public date: Date = new Date();
  public company: string = environment.company;

  constructor() { }

  ngOnInit(): void {
  }

}
