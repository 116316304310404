import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'admins',
    loadChildren: () => import('./admins/admins.module').then(m => m.AdminsModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'apps',
    loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'devices',
    loadChildren: () => import('./nodes/nodes.module').then(m => m.NodesModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'gateways',
    loadChildren: () => import('./gateways/gateways.module').then(m => m.GatewaysModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'schemes',
    loadChildren: () => import('./schemes/schemes.module').then(m => m.SchemesModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'sources',
    loadChildren: () => import('./sources/sources.module').then(m => m.SourcesModule),
    canLoad: [AuthGuardService]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
